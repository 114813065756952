import React, { useState, useEffect } from 'react';
import './App.css';
import About from './components/About/About';
import Home from './components/Hero/Home';
import Navbar from './components/Navbar/Navbar';
import Products from './components/Products/Products';
import Team from './components/Team/Team';
import ScrollWrapper from './components/ScrollWrapper';

function App() {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setActiveSection('home');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log('Current active section:', activeSection);

  return (
    <>
      <Navbar activeSection={activeSection} />
      <ScrollWrapper setActiveSection={setActiveSection}>
        <Home id="home" />
        <About id="about" />
        <Products id="products" />
        <Team id="team" />
      </ScrollWrapper>
    </>
  );
}

export default App;