import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Products.css';
import aiThumbnailImage from '../../assets/images/ai-thumbnail.png';
import More from '../../assets/images/products.png';

const Products = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const projects = [
    { 
      image: aiThumbnailImage, 
      name: 'AIThumbnail', 
      description: 'Create youtube thumbnails in a single click!',
      link: 'https://www.aithumbnail.in' 
    },
    { 
      image: More, 
      name: 'More exciting products on the way!', 
      description: 'Stay tuned for more!',
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  return (
    <div className="products-container" id="products">
      <div className="products-left">
        <h2 className="products-title">OUR PRODUCTS</h2>
        <p className="products-subtitle">Our first product is live! </p>
        <div className="carousel-container">
          <AnimatePresence initial={false}>
            {projects.map((project, index) => {
              const position = (index - currentIndex + projects.length) % projects.length;
              return (
                <motion.div
                  key={project.name}
                  className="carousel-card"
                  style={{
                    zIndex: projects.length - position,
                  }}
                  initial={{ scale: 0.8, y: 50, opacity: 0 }}
                  animate={{
                    scale: 1 - position * 0.05,
                    y: position * 20,
                    opacity: 1 - position * 0.2,
                    rotateY: position * 5,
                  }}
                  exit={{ scale: 0.8, y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {project.image && <img src={project.image} alt={project.name} />}
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      <div className="products-right">
        <div className="project-info">
          <h3>{projects[currentIndex].name}</h3>
          <p>{projects[currentIndex].description}</p>
          {projects[currentIndex].link && (
            <a href={projects[currentIndex].link} target="_blank" rel="noopener noreferrer" className="product-link-button">
              Try It Now
            </a>
          )}
        </div>
        <div className="carousel-controls">
          <button onClick={prevSlide}>&lt;</button>
          <span>{currentIndex + 1}/{projects.length}</span>
          <button onClick={nextSlide}>&gt;</button>
        </div>
      </div>
    </div>
  );
};

export default Products;