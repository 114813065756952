import React, { useEffect, useRef } from 'react';

const ScrollWrapper = ({ children, setActiveSection }) => {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            console.log(`Section ${entry.target.id} is now visible`);
          }
        });
      },
      { 
        threshold: 0.3, // Trigger when 30% of the element is visible
        rootMargin: '-10% 0px -10% 0px' // Slightly adjust the intersection area
      }
    );

    observerRef.current = observer;

    React.Children.forEach(children, child => {
      const element = document.getElementById(child.props.id);
      if (element) {
        observer.observe(element);
        console.log(`Observing element with id: ${child.props.id}`);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [children, setActiveSection]);

  return (
    <>
      {children}
    </>
  );
};

export default ScrollWrapper;