import React, { useState } from 'react';
import { motion} from 'framer-motion';
import './Team.css';
import Paneer from '../../assets/images/paneerpicture.jpg';
import Pramood from '../../assets/images/pramodpicture.jpg';
import Samarthhh from '../../assets/images/samarthpicture.jpg';
import Gourav from '../../assets/images/gouravpicture.jpeg';

const teamMembers = [
  {
    id: 1,
    name: 'Samarth',
    designation: 'Cloud Developer',
    image: Samarthhh,
    interests: ['Music', 'Swimming', 'Chess'],
    emojis: ['🎶', '🌊', '♟️'],
  },
  {
    id: 2,
    name: 'Gourav',
    designation: 'FullStack Engineer',
    image: Gourav,
    interests: ['Gardening', 'Painting', 'Photography'],
    emojis: ['🎨', '📷', '🥾'],
  },
  {
    id: 3,
    name: 'Pramod',
    designation: 'AI Engineer',
    image: Pramood,
    interests: ['Reading', 'Film making', 'Writing'],
    emojis: ['📚', '💪', '🎥' ],
  },
  {
    id: 4,
    name: 'Praneetha',
    designation: 'Designer & Frontend Engineer',
    image: Paneer,
    interests: ['Travelling', 'Socialising', 'Dancing'],
    emojis: ['🍝', '✈️', '💃'],
  }
];

const TeamMember = ({ member }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <motion.div
        className="team-member"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.15, zIndex: 10 }}
      >
        <img src={member.image} alt={member.name} />
        {isHovered && (
          <motion.div
            className="tooltip"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            <div className="emojis">
              {member.emojis.map((emoji, index) => (
                <span key={index} className="emoji">
                  {emoji}
                </span>
              ))}
            </div>
            <h3>{member.name}</h3>
            <p>{member.designation}</p>
            <p className="interests">{member.interests.join(', ')}</p>
          </motion.div>
        )}
      </motion.div>
    );
  };
  const DURATION = 0.25;
const STAGGER = 0.025;

const FlipLink = ({ children, href }) => {
  return (
    <motion.a
      initial="initial"
      whileHover="hovered"
      href={href}
      className="flip-link"
    >
      <div className="flip-link-content">
        {children.split("").map((l, i) => (
          <motion.span
            key={i}
            variants={{
              initial: { y: 0 },
              hovered: { y: "-100%" },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * i,
            }}
            className="flip-link-char"
          >
            {l}
          </motion.span>
        ))}
      </div>
      <div className="flip-link-content flip-link-content-hover">
        {children.split("").map((l, i) => (
          <motion.span
            key={i}
            variants={{
              initial: { y: "100%" },
              hovered: { y: 0 },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * i,
            }}
            className="flip-link-char"
          >
            {l}
          </motion.span>
        ))}
      </div>
    </motion.a>
  );
};

const RevealLinks = () => {
  return (
    <div className="reveal-links" id="team">
      <FlipLink href="mailto:support@hacktogether.dev">Mail</FlipLink>
      <FlipLink href="https://twitter.com/HacktogetherDev">Twitter</FlipLink>
      <FlipLink href="#">Instagram</FlipLink>
    </div>
  );
};

const Team = () => {
  return (
    <div className="bento-grid">
      <div className="left-side card">
        <div className="team-section">
          <div className="team-members">
            {teamMembers.map((member) => (
              <TeamMember key={member.id} member={member} />
            ))}
          </div>
        </div>
        <h2 className="team-title">The team that does the cool stuff</h2>
      </div>
      <div className="right-side card">
        <RevealLinks />
      </div>
    </div>
  );
};

export default Team;