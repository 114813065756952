import React from 'react';
import { motion } from 'framer-motion';
import './About.css';
import Paneer from '../../assets/images/paneerpicture.jpg';
import Pramood from '../../assets/images/pramodpicture.jpg';
import Samarthhh from '../../assets/images/samarthpicture.jpg';
import Gourav from '../../assets/images/gouravpicture.jpeg';

const About = () => {
  const mainText = "Transforming Ideas into Digital Realities";
  const subText = "We're a team of passionate creators, innovators, and problem-solvers dedicated to crafting extraordinary digital experiences.";
  const messages = [
    { text: "Hey team, wild idea: an app that turns your grocery list into recipe suggestions. Thoughts?", isUser: false },
    { text: "Ooh, like a reverse meal planner? That's pretty clever!", isUser: true },
    { text: "Nice! We could use AI to create meal ideas from whatever's on the list.", isUser: false },
    { text: "Love it! I can see the UI already. Clean, simple, with a big 'magic wand' button to transform the list.", isUser: true },
    { text: "Awesome. Let's get onto it!", isUser: false }
  ];

  const otherPictures = [Pramood, Samarthhh, Gourav];

  const distributedPictures = messages.map((message, index) => {
    if (message.isUser) {
      return Paneer;
    } else {
      return otherPictures[index % otherPictures.length];
    }
  });

  return (
    <div className="about-container" id="about">
      <div className="about-left">
        <motion.h2 
          className="about-title"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {mainText}
        </motion.h2>
        <motion.p 
          className="about-subtitle"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {subText}
        </motion.p>
      </div>
      <div className="about-right">
        <motion.div
          className="pulsating-circle"
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.5, 0.8, 0.5]
          }}
          transition={{ 
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="chat-container">
          {messages.map((message, index) => (
            <motion.div
              key={index}
              className={`message ${message.isUser ? 'user' : 'bot'}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div 
                className="profile-pic" 
                style={{
                  backgroundImage: `url(${distributedPictures[index]})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              ></div>
              <div className="message-content">{message.text}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;