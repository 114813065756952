import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Navbar.css';

const Navbar = ({ activeSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (link) => {
    setIsOpen(false);
    
    const element = document.querySelector(link);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">HackTogether</div>
        <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
          <motion.a
            href="#home"
            className={activeSection === 'home' ? 'active' : ''}
            onClick={() => handleLinkClick('#home')}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Home
          </motion.a>
          <motion.a
            href="#about"
            className={activeSection === 'about' ? 'active' : ''}
            onClick={() => handleLinkClick('#about')}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            About Us
          </motion.a>
          <motion.a
            href="#products"
            className={activeSection === 'products' ? 'active' : ''}
            onClick={() => handleLinkClick('#products')}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Our Products
          </motion.a>
          <motion.a
            href="#team"
            className={activeSection === 'team' ? 'active' : ''}
            onClick={() => handleLinkClick('#team')}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Contact Us
          </motion.a>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;