import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import gsap from 'gsap';
import './Home.css';

const Home = () => {
  const controls = useAnimation();
  const buttonRef = useRef(null);
  const unicornRef = useRef(null);

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  useEffect(() => {
    const button = buttonRef.current;
    const unicorn = unicornRef.current;

    const showUnicorn = () => {
      gsap.to(unicorn, { opacity: 1, x: -10, duration: 0.3, ease: 'power2.out' });
    };

    const hideUnicorn = () => {
      gsap.to(unicorn, { opacity: 0, x: 0, duration: 0.3, ease: 'power2.in' });
    };

    button.addEventListener('mouseenter', showUnicorn);
    button.addEventListener('mouseleave', hideUnicorn);

    return () => {
      button.removeEventListener('mouseenter', showUnicorn);
      button.removeEventListener('mouseleave', hideUnicorn);
    };
  }, []);

  const handleKnowUsClick = () => {
    const contactSection = document.getElementById('team');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.8, ease: 'easeOut' }
    }
  };

  return (
    <div className="home-container" id="home">
      <motion.h1 
        className="title"
        initial="hidden"
        animate={controls}
        variants={variants}
      >
        We BUILD<br />What Matters
      </motion.h1>
      <motion.p 
        className="description"
        initial="hidden"
        animate={controls}
        variants={variants}
        transition={{ delay: 0.2 }}
      >
        At HackTogether, we're a group of indie hackers passionate about solving real problems. From idea to execution, we focus on building products that are simple, effective, and tailored to make life easier.
      </motion.p>
      <motion.div 
        className="button-container"
        initial="hidden"
        animate={controls}
        variants={variants}
        transition={{ delay: 0.4 }}
      >
        <button ref={buttonRef} className="cta-button" onClick={handleKnowUsClick}>Know Us</button>
        <div ref={unicornRef} className="unicorn">🦄</div>
      </motion.div>
    </div>
  );
};

export default Home;